import { LangButton } from "./LangButton"

type HeaderProps = {
  i18n: any
}

export function Header({ i18n }: HeaderProps) {
  return (
    <header className="flex flex-row items-center justify-center h-16 px-8 text-white bg-red-800">
      <div className="flex flex-row items-center justify-between w-full max-w-5xl">
        <h1 className="text-2xl font-bold uppercase">Bihari Music</h1>
        <LangButton i18n={i18n} />
      </div>
    </header>
  )
}
