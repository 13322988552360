import { TFunction } from "react-i18next"
import { GrGallery } from "react-icons/gr"
import { YoutubeEmbed } from "../youtube/YoutubeEmbed"
import { links, galleryUrls } from "src/assets/showLinks"
import { useState } from "react"
import Lightbox from "react-image-lightbox"

type OneShowProps = {
  t: TFunction<"translation", undefined>
  i: number
  code: string
}

export function OneShow({ t, i, code }: OneShowProps) {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const images = galleryUrls[code]
  const urlPrefix = `/images/gallery/${code}/`
  return (
    <div className="grid grid-cols-1 my-8 lg:gap-4 lg:grid-cols-3">
      <div className="flex flex-col justify-center col-span-1 lg:justify-start">
        <YoutubeEmbed embedId={links[t(`shows.showList.${i}.code`)]} />
      </div>
      <div className="flex flex-col justify-center col-span-2">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-xl">{t(`shows.showList.${i}.title`)}</h2>
          <GrGallery className="text-xl cursor-pointer" onClick={() => setIsOpen(true)} />
        </div>
        <p className="my-2 text-justify">{t(`shows.showList.${i}.text`)}</p>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={`${urlPrefix}${images[photoIndex]}`}
          nextSrc={`${urlPrefix}${images[(photoIndex + 1) % images.length]}`}
          prevSrc={`${urlPrefix}${images[(photoIndex + images.length - 1) % images.length]}`}
          onCloseRequest={() => {
            setIsOpen(false)
            setPhotoIndex(0)
          }}
          onImageLoadError={() => setPhotoIndex((photoIndex + 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        />
      )}
    </div>
  )
}
