import React from "react"
import "./App.css"
import { PageLayout } from "./components/layout/PageLayout"
import { useTranslation } from "react-i18next"
import "./i18n/config"
import { Shows } from "./components/shows/Shows"
import { Contacts } from "./components/contacts/Contacts"
import { useStore } from "./util/zustand"
import { LangSelectorModal } from "./components/langSelector/LangSelectorModal"
import { Association } from "./components/association/Association"
import { VideoButton } from "./components/links/VideoButton"
function App() {
  const { t, i18n } = useTranslation()
  const { open: langChangeOpen } = useStore()
  return (
    <>
      <PageLayout i18n={i18n}>
        <div className="max-w-4xl mx-auto my-16">
          <img src={"/images/main.jpg"} alt="main" />
          <div className="flex flex-col gap-4 my-8 lg:flex-row lg:justify-between">
            <VideoButton link="https://youtu.be/Plyj1XqNowo" text="Bihari Music at Kaliningrad" />
            <VideoButton link="https://youtu.be/C9FAH7tyBNU" text="Bihari Band" />
            <VideoButton link="https://youtu.be/ElmYHq9wqVU" text="Bihari Debrecen Gipsy Band" />
          </div>
          <section>
            <h1 className="text-3xl">{t("introduction.title")}</h1>
            <p className="my-4 text-justify">{t("introduction.text")}</p>
          </section>

          <Shows t={t} />
          <Association t={t} />
          <Contacts t={t} />
        </div>
        <>{langChangeOpen && <LangSelectorModal i18n={i18n} t={t} />}</>
      </PageLayout>
    </>
  )
}

export default App
