import { TFunction } from "react-i18next"
import defaultContent from "src/i18n/hu/translation.json"
type ContactProps = {
  t: TFunction<"translation", undefined>
}

export function Contacts({ t }: ContactProps) {
  const { contacts } = defaultContent.contact
  return (
    <div className="my-2">
      <h1 className="text-3xl">{t("contact.title")}</h1>
      {contacts.map((e, i) => (
        <div className="my-2">
          <h3>{t(`contact.contacts.${i}.name`, { defaultValue: "" })}</h3>
          <p>{t(`contact.contacts.${i}.title`, { defaultValue: "" })}</p>
          {e.details.map((_, di) => (
            <p>{t(`contact.contacts.${i}.details.${di}`, { defaultValue: "" })}</p>
          ))}
        </div>
      ))}
    </div>
  )
}
