type YoutubeEmbedProps = {
  embedId: string
}
export function YoutubeEmbed({ embedId }: YoutubeEmbedProps) {
  return (
    <div>
      <iframe
        width={"100%"}
        title="embeded youtube list"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        key={embedId}
        src={`https://www.youtube.com/embed/${embedId}`}
      />
    </div>
  )
}
