import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from "i18next-browser-languagedetector"

export const resources = {
    hu: {
        translation: require('./hu/translation.json')
    },
    en: {
        translation: require('./en/translation.json')
    },
    de: {
        translation: require('./de/translation.json')
    },
    fr: {
        translation: require('./fr/translation.json')
    },
    it: {
        translation: require('./it/translation.json')
    },
    sp: {
        translation: require('./sp/translation.json')
    }
} as const

export const supportedLanguages = Object.keys(resources)

i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        supportedLngs: supportedLanguages,
        resources,
    })