export const links: {
    [key: string]: string
} = {
    "bkc": "EmhyMV5xbxc?list=PLUzPRqoXlR2vhYvAJBHTYnjbr_gdAAVMX",
    "bb": "GqqoeGopgoc?list=PLUzPRqoXlR2sS0t_HyiNeB5IO3ZYRArtz",
    "og": "R7_M1DEIp2A?list=PLUzPRqoXlR2tNnCYAvYjb1XOV5RTTxQhW",
    "mnm": "Ne1UjQHB66c?list=PLUzPRqoXlR2tPfLirThO3f0eo3F5VOIO9",
    "bc": "AQImOTDUDx4?list=PLUzPRqoXlR2uuhAKI9f6j0xb9uFuZykzR"
}

export const galleryUrls: {
    [key: string]: string[]
} = {
    "bkc": [
        "01.jpg",
        "02.jpg",
        "03.jpg",
        "04.jpg",
        "05.jpg",
        "06.jpg",
        "07.jpg",
        "08.jpg",
        "09.jpg",
        "10.jpg",
        "11.jpg",
        "12.jpg",
        "13.jpg",
        "14.jpg",
        "15.jpg",
    ],
    "bb": [
        "06.jpg",
        "07.jpg",
        "09.JPG",
        "10.jpg",
        "11.jpg",
        "12.jpg",
        "13.jpg",
        "14.jpg",
        "15.jpg",
        "16.jpg"
    ],
    "og": [
        "09.jpg",
        "10.jpg",
        "08.jpg"
    ],
    "mnm": [
        "01.jpg",
        "08.JPG",
        "11.jpg",
        "19.JPG"
    ],
    "bc": [
        "02.jpg",
        "08.JPG",
        "01.jpg"
    ]
}
