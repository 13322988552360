import { useStore } from "src/util/zustand"

import { supportedLanguages } from "src/i18n/config"
import clsx from "clsx"
type LangSelectorModalProps = {
  i18n: any
  t: any
}

export function LangSelectorModal({ i18n, t }: LangSelectorModalProps) {
  const { flip: langChangeFlip } = useStore()
  return (
    <div className="fixed inset-0 flex flex-row items-center justify-center w-full h-full overflow-y-auto bg-black bg-opacity-80">
      <div className="flex flex-col items-center justify-center w-3/4 max-w-2xl px-6 py-16 text-center bg-white rounded-2xl">
        <h1 className="text-2xl">{t("langSelect.title")}</h1>
        <p className="text-xl">
          {t("langSelect.current")}: {i18n.language}
        </p>
        <ul className="grid grid-cols-3 gap-4 my-4 text-2xl">
          {supportedLanguages
            .sort((a, b) => a.localeCompare(b))
            .map((e) => (
              <li
                onClick={() => {
                  i18n.changeLanguage(e)
                  langChangeFlip()
                }}
                className={clsx(
                  "cursor-pointer text-gray-700",
                  e === i18n.language && "font-black"
                )}
              >
                {e}
              </li>
            ))}
        </ul>
        <span
          onClick={() => {
            langChangeFlip()
          }}
          className="w-32 mt-8 text-white bg-red-800 cursor-pointer rounded-3xl"
        >
          {t("langSelect.close")}
        </span>
      </div>
    </div>
  )
}
