import { TFunction } from "react-i18next"
import defaultContent from "src/i18n/hu/translation.json"
import { YoutubeEmbed } from "../youtube/YoutubeEmbed"
import { links } from "src/assets/showLinks"
import { GrGallery } from "react-icons/gr"
import Lightbox from "react-image-lightbox"
import { useState } from "react"
import "react-image-lightbox/style.css"
import { OneShow } from "./OneShow"

type ShowsProps = {
  t: TFunction<"translation", undefined>
}

export function Shows({ t }: ShowsProps) {
  const shows = defaultContent.shows.showList
  return (
    <>
      <div>
        <h1 className="text-3xl">{t("shows.title")}</h1>
        {shows.map((v, i) => (
          <OneShow t={t} i={i} code={v.code} />
        ))}
      </div>
    </>
  )
}
