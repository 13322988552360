import { TFunction } from "react-i18next"

type AssociationProps = {
  t: TFunction<"translation", undefined>
}

export function Association({ t }: AssociationProps) {
  return (
    <div>
      <h1 className="text-3xl">{t("association.title")}</h1>
      <p className="my-4">{t("association.text")}</p>
      <p className="my-4">{t("association.lower")}</p>
    </div>
  )
}
