import create from "zustand"

interface LangSelectorState {
    open: boolean
    flip: (b?: boolean) => void
}

export const useStore = create<LangSelectorState>((set) => ({
    open: false,
    flip: () => {
        set((state) => ({
            open: !state.open
        }))
    }
}))