import { BsFillCameraVideoFill } from "react-icons/bs"

type VideoButtonProps = {
  link: string
  text: string
}

export function VideoButton({ link, text }: VideoButtonProps) {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className="flex flex-row items-center gap-2 text-lg font-medium cursor-pointer text-bold">
        <p className="underline">{text}</p>
        <BsFillCameraVideoFill />
      </div>
    </a>
  )
}
