import { useState } from "react"
import { useStore } from "src/util/zustand"
import { MdLanguage } from "react-icons/md"

type LangButtonType = {
  i18n: any
}

export function LangButton({ i18n }: LangButtonType) {
  const { flip: langChangeFlip } = useStore()
  return (
    <>
      <div>
        <span
          className="text-3xl cursor-pointer"
          onClick={() => {
            langChangeFlip()
          }}
        >
          <MdLanguage color="white" />
        </span>
      </div>
    </>
  )
}
