import { Footer } from "../footer/Footer"
import { Header } from "../header/Header"

type PageLayoutProps = {
  children: JSX.Element | JSX.Element[] | false
  i18n: any
}

export function PageLayout({ children, i18n }: PageLayoutProps) {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <Header i18n={i18n} />
      <main className="flex-1 px-8">{children}</main>
      <Footer />
    </div>
  )
}
